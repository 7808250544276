<template>
	<span>
		<v-dialog
			@click:outside="listOnCancel(true)"
			@keydown.esc="listOnCancel(true)"
			v-model="_importDialog"
			:max-width="maxWidth">
			<v-card>
				<slot
					:name="'title'"
					:title="title"
					:WIP="WIP"
					:VERYWIP="VERYWIP">
					<v-card-title
						class="primary lighten-1 font-weight-bold"
						:class="{ WIP: WIP, VERYWIP: VERYWIP }">
						<span class="text-h5">{{ WIP ? "WIP: " : "" }}{{ title }}</span>
					</v-card-title>
				</slot>
				<input
					@change="fileSelect"
					ref="fileUpload"
					:accept="format.join(', ')"
					type="file"
					hidden />
				<v-card-text class="pa-0">
					<v-stepper
						flat
						v-model="currentStep">
						<v-stepper-header v-if="steps > 1">
							<v-stepper-step
								:editable="maxStep > 1"
								:complete="currentStep > 1"
								:step="1">
								<slot name="step-1">Select file</slot>
							</v-stepper-step>
							<template v-for="step in steps - 1">
								<v-divider
									:class="`${currentStep > step ? `primary` : ``}`"
									:key="`divider-${step}`"></v-divider>
								<v-stepper-step
									:key="`step-${step}`"
									:complete="currentStep > step + 1"
									:editable="maxStep > step"
									:step="step + 1">
									<slot :name="`step-${step + 1}-header`">
										Step {{ step + 1 }}
									</slot>
								</v-stepper-step>
							</template>
						</v-stepper-header>
						<v-stepper-items>
							<v-stepper-content
								class="pa-0 ma-0"
								step="1">
								<v-slide-y-transition leave-absolute>
									<!--  -->
									<v-card
										v-if="!listUpload && _importDialog"
										@dragover="dragOver"
										@dragenter="dragOver"
										@dragleave="dragOver"
										@drop="fileDrop"
										@click="clickInput"
										:color="dragging ? 'primary' : 'menu_background'"
										class="dropHere d-flex flex-column justify-center align-center"
										:class="wrongInput ? 'shake' : ''"
										dense>
										<v-icon
											:class="[
												dragging ? 'kajot-text--text' : 'primary--text',
												wrongInput ? 'error--text' : '',
												listLoading ? 'loading' : '',
											]"
											class="my-4"
											size="60">
											mdi-arrow-up-bold-circle
										</v-icon>
										<template v-if="!wrongInput && !listUpload && importDialog">
											<div class="text-center">
												<slot
													name="drop-info"
													:dragging="dragging">
													<b
														:class="
															dragging ? 'kajot-text--text' : 'primary--text'
														"
														class="text-uppercase">
														DROP
													</b>
													<span>your {{ dropFileType }} file,</span>
													<template v-if="!dragging">
														<b
															:class="
																dragging ? 'kajot-text--text' : 'primary--text'
															"
															class="text-uppercase">
															CLICK
														</b>
														here
														<br />
														or
														<b
															:class="
																dragging ? 'kajot-text--text' : 'primary--text'
															"
															class="text-uppercase">
															PASTE
														</b>
													</template>
													to
													<b
														:class="
															dragging ? 'kajot-text--text' : 'primary--text'
														"
														class="text-uppercase">
														import.
													</b>
												</slot>
											</div>
										</template>
										<v-progress-circular
											v-show="listLoading"
											:value="parseProgress"
											color="primary"></v-progress-circular>
										<b
											v-if="wrongInput"
											class="error--text">
											{{ errorMessage }}
										</b>
									</v-card>
								</v-slide-y-transition>
								<v-slide-y-transition
									leave-absolute
									hide-on-leave>
									<v-card
										key="card-uploaded"
										@dragover="dragOver"
										@dragenter="dragOver"
										@dragleave="dragOver"
										@drop="fileDrop"
										@click="clickInput"
										:color="dragging ? 'primary' : 'menu_background'"
										class="dropHere d-flex flex-column justify-center align-center"
										:class="{ shake: wrongInput }"
										v-if="listUpload">
										<v-progress-circular
											:value="parseProgress"
											color="primary"
											v-show="listLoading"></v-progress-circular>
										<v-slide-y-reverse-transition
											leave-absolute
											hide-on-leave>
											<div
												class="dropHere d-flex flex-column justify-center align-center"
												v-if="!listLoading && listUpload"
												:class="wrongInput ? 'error--text' : ''">
												<v-icon
													:class="[
														dragging ? 'kajot-text--text' : 'primary--text',
														wrongInput ? 'error--text' : '',
													]"
													class="my-4"
													size="60">
													mdi-file-cloud
												</v-icon>
												<div class="mb-2">
													<b>{{ results.meta?.name }}</b>
													<b
														v-if="tempMode !== undefined && tempMode != ''"
														class="primary--text"
														:class="wrongInput ? 'error--text' : ''">
														{{ tempMode ? "Blacklist" : "Whitelist" }}
													</b>
												</div>
												<slot
													name="file-detail"
													:data="tempData">
													<p class="font-weight-bold">
														{{ `${tempData?.length} records` }}
													</p>
												</slot>
												<span>
													{{ Math.floor(results.meta?.size / 10.24) / 100 }} kB
												</span>
												<p v-if="!results.meta?.fromPaste">
													Modified:
													{{
														new Date(
															results.meta?.lastModified
														).toLocaleString()
													}}
												</p>
												<p v-else>
													Uploaded:
													{{
														new Date(
															results.meta?.lastModified
														).toLocaleString()
													}}
												</p>
											</div>
										</v-slide-y-reverse-transition>
									</v-card>
								</v-slide-y-transition>
							</v-stepper-content>
							<v-stepper-content
								v-for="step in steps - 1"
								:step="step + 1"
								class="pa-0 ma-0"
								:key="`content-${step + 1}`">
								<slot
									:parserResult="parserResult"
									:step="step + 1"
									:name="`step-${step + 1}-content`">
									Step {{ step + 1 }} content
								</slot>
							</v-stepper-content>
						</v-stepper-items>
					</v-stepper>
					<!-- New Addition -->
				</v-card-text>
				<v-card-actions class="justify-space-between w-100">
					<slot
						v-if="currentStep == 1"
						class="d-flex flex-row"
						name="actions1">
						<div>
							<slot
								name="actions1-cancel"
								:cancel="listOnCancel">
								<v-btn
									text
									@click="listOnCancel(true)">
									<slot name="actions1-cancel-text">Cancel</slot>
								</v-btn>
							</slot>
						</div>
						<v-spacer v-if="!noHint"></v-spacer>
						<slot
							:listUpload="listUpload"
							name="actions1-extra"></slot>
						<v-tooltip
							v-if="!noHint"
							top
							color="transparent">
							<template v-slot:activator="{ on: tooltip }">
								<v-btn
									v-on="tooltip"
									fab
									text
									color="warning">
									<v-icon size="25">mdi-information</v-icon>
								</v-btn>
							</template>
							<v-card max-width="300">
								<v-card-title class="warning lighten-1 font-weight-bold">
									<span class="text-h5">
										<slot name="hint-title">Imported data format</slot>
									</span>
								</v-card-title>
								<v-card-text><slot name="hint-body"></slot></v-card-text>
							</v-card>
						</v-tooltip>
						<v-spacer v-if="!noHint"></v-spacer>
						<div>
							<slot
								v-if="currentStep < steps"
								name="actions1-next"
								:listUpload="listUpload"
								:step="currentStep"
								:next="stepperNext">
								<v-btn
									text
									@click="stepperNext()">
									<slot name="actions1-next-text">Next</slot>
								</v-btn>
							</slot>
							<v-slide-y-transition>
								<slot
									:submit="submitList"
									name="actions1-submit">
									<v-btn
										v-if="!useConfirm"
										:disabled="!listUpload"
										key="listButtonKey"
										color="primary"
										@click="submitList"
										text>
										<slot name="actions1-submit-text">
											{{ submitButtonText }}
										</slot>
									</v-btn>
									<confirm-dialog
										v-else
										persistent
										:confirmBtnText="'Overwrite'"
										:cancelBtnText="'Back'"
										:disabled="!listUpload"
										:shouldShow="listMode !== 'No List'"
										@confirm="submitList">
										<template #body>
											This action will overwrite all your currently set list.
										</template>
										<template #default="{ showConfirm }">
											<v-btn
												key="listButtonKey"
												color="primary"
												v-if="listUpload"
												@click="showConfirm"
												text>
												Submit
											</v-btn>
										</template>
									</confirm-dialog>
								</slot>
							</v-slide-y-transition>
						</div>
					</slot>
					<slot
						v-if="currentStep != 1"
						:cancel="listOnCancel"
						:prev="stepperBack"
						:next="stepperNext"
						:submit="submitList"
						:name="`actions${currentStep}`"></slot>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<ConfirmDialog
			titleClass="error"
			cancelBtnText="Back"
			:shouldShow="!dontShow"
			cancelBtnColor="kajot-text"
			confirmBtnText="Import Anyway"
			ref="uncheckedCSVConfirmDialog"
			titleText="Importing CSV without checksum"
			@confirm="
				onCSVParserDone({
					data: noChecksumData.data,
					mode: noChecksumData.mode,
					meta: noChecksumData.meta,
				})
			"
			@cancel="onCancelImport">
			<template v-slot:body>
				<h3 class="error--text">
					Note: The validity of subject data CANNOT be guaranteed.
				</h3>
				<p>
					Your CSV file is missing our checksum validation field. For security
					reasons, we recommend using CSV files generated by the LOGAN app.
				</p>
				<v-row class="d-flex flex-row align-center justify-space-around">
					<v-col cols="1">
						<v-checkbox v-model="dontShow"></v-checkbox>
					</v-col>
					<v-col>
						<span>Don't ask again in this session</span>
					</v-col>
				</v-row>
			</template>
		</ConfirmDialog>
	</span>
</template>
<script>
import { mapGetters } from "vuex";
import csvParseWorker from "worker-loader!../../script/csvParser.worker";
import ConfirmDialog from "../../components/shared/ConfirmDialog2.vue";
import { findDelimiter } from "../../constants/helpers";
import permissions from "../../mixins/permissions.js";
import table from "../../mixins/table.js";
import mime from "mime";

File.prototype.equals = function (el) {
	return (
		this.size == el.size &&
		this.name == el.name &&
		this.lastModified == el.lastModified &&
		this.type == el.type
	);
};

export default {
	components: {
		ConfirmDialog,
	},
	mixins: [permissions, table],
	model: {
		event: "change",
		prop: "listEdit",
	},
	props: {
		widths: { type: Array },
		WIP: {
			type: Boolean,
			default: false,
		},
		VERYWIP: {
			type: Boolean,
			default: false,
		},
		title: {
			default: "Import List",
			type: String,
		},
		submitButtonText: {
			default: "Submit",
			type: String,
		},
		steps: {
			default: 1,
			type: Number,
		},
		noHint: {
			type: Boolean,
			default: false,
		},
		width: {
			type: Number,
		},
		parser: {
			type: String,
		},
		dropFileType: {
			type: String,
			default: "CSV",
		},
		useConfirm: {
			default: false,
			type: Boolean,
		},
		importDialog: {
			default: false,
			type: Boolean,
		},
		listEdit: {
			type: Array,
			default: function () {
				return [];
			},
		},
		format: {
			type: Array,
			default: () => {
				return ["text/csv", "application/vnd.ms-excel"];
			},
		},
		validator: {
			type: Function,
			default: () => {
				return true;
			},
		},
		errorTimeout: {
			type: Number,
			default: 1000,
		},
	},
	data() {
		return {
			parserResult: {},
			maxStep: 1,
			currentStep: 1,
			lastFile: "",
			listMode: "Blacklist",
			dontShow: localStorage.getItem("logan2:importDialog:hide") ?? false,
			formatDialog: false,
			progress: 0,
			parseProgress: 0,
			csvData: null,
			csvParseWorker: new csvParseWorker(),
			loading: false,
			reader: new FileReader(),
			insiderCounter: 0,
			results: [],
			listUpload: false,
			listLoading: false,
			dragging: false,
			wrongInput: false,
			options: {},
			tempMode: false,
			tempData: [],
			tempPlayer: null,
			offerCode: "",
			noChecksumData: null,
			controller: undefined,

			errorMessage: "Make sure you grab the correct files!",
		};
	},
	computed: {
		allowedExtensions() {
			return this.format
				.map((cur) => mime.getExtension(cur))
				.filter(Boolean)
				.join(", ")
				.replace(/, ([^,]+)$/, " and $1");
		},
		stepWidth() {
			return this.widths?.[this.currentStep - 1] ?? this.width;
		},
		maxWidth() {
			if (!this.width && !this.widths) return 500;
			if (!this.stepWidth) return undefined;
			return Math.max(
				Math.min(window.innerWidth, Math.max(500, 170 * this.steps)),
				this.stepWidth
			);
		},
		_importDialog: {
			get() {
				return this.importDialog;
			},
			set(val) {
				this.$emit("update:importDialog", val);
			},
		},
		...mapGetters("breadcrumbs", {
			lastCrumb: "lastCrumb",
		}),
		filename() {
			const today = new Date();
			return `${this.offerCode}-${this.listMode}-${today.getDate()}_${
				today.getMonth() + 1
			}_${today.getFullYear()}`;
		},
		blobUrl() {
			const csvContent = "data:text/csv;charset=utf-8," + this.csvData;
			return encodeURI(csvContent);
		},
	},
	watch: {
		importDialog(val) {
			if (val) {
				console.log("adding paste listener");
				addEventListener("paste", this.grabPaste);
			} else {
				console.log("removing paste listener");
				removeEventListener("paste", this.grabPaste);
			}
		},
	},

	methods: {
		async grabPaste(e) {
			const text = e.clipboardData.getData("text");
			this.listLoading = true;
			this.listUpload = false;
			const fakeF = new File([text], "Clipboard", {
				type: "text/plain",
				lastModified: Date.now(),
			});
			await this.sendToParser(text, fakeF, true);
		},
		stepperNext() {
			this.currentStep++;
			this.maxStep = Math.max(this.maxStep, this.currentStep);
			this.$emit("step", this.currentStep, this.tempData);
		},
		stepperBack() {
			this.currentStep--;
			this.$emit("step", this.currentStep, this.tempData);
		},
		showFormat() {
			this.formatDialog = true;
		},
		async submitList() {
			if (this.parser === "offerPlayers") {
				this.parserResult.mode = this.tempMode;
			}
			this.$emit("submit", this.parserResult);
			this.insiderCounter = 0;
			this.maxStep = 1;
			this.currentStep = 1;
			this.$refs.fileUpload.value = "";
			this.lastFile = "";
			this.listUpload = false;
			this.fileText = undefined;
			this._importDialog = false;
			this.tempData = [];
			this.tempMode = "";
			this.load();
		},
		listOnCancel(close) {
			if (close) {
				this._importDialog = false;
			}
			this.listUpload = false;
			this.fileText = undefined;
			this.listLoading = false;
			this.lastFile = "";
			this.insiderCounter = 0;
			this.parseProgress = 0;
			this.maxStep = 1;
			this.currentStep = 1;
			this.$refs.fileUpload.value = "";
			this.tempData = [];
			this.tempMode = "";
			this.parserResult = {};
			this.results = [];
			this.$emit("close");
		},
		clickInput() {
			this.$refs.fileUpload.click();
		},
		dragOver(e) {
			e.stopPropagation();
			e.preventDefault();

			if (e.type == "dragenter") {
				this.insiderCounter += 1;
			} else if (e.type == "dragleave") {
				this.insiderCounter -= 1;
			}
			this.dragging = this.insiderCounter > 0;
			return false;
		},
		fileDrop(e) {
			this.insiderCounter = 0;
			e.stopPropagation();
			e.preventDefault();
			this.dragging = false;
			const files = e.dataTransfer.files;
			if (files.length != 1) {
				this.wrongInput = true;
				setTimeout(() => {
					this.wrongInput = false;
				}, this.errorTimeout);
				return;
			}
			if (!this.format.includes(files[0].type)) {
				this.errorMessage = `Unsupported file type. \n Allowed extensions: ${this.allowedExtensions}`;
				this.wrongInput = true;
				setTimeout(() => {
					this.wrongInput = false;
				}, this.errorTimeout);
				return;
			}
			const file = files;
			this.fileHandler(file[0]);
		},
		fileSelect(e) {
			const files = e.target.files;
			if (files.length == 0) return;
			if (files.length > 1) {
				this.wrongInput = true;
				this.$refs.fileUpload.value = "";
				setTimeout(() => {
					this.wrongInput = false;
				}, this.errorTimeout);
				return;
			}
			if (!this.format.includes(files[0].type)) {
				this.errorMessage = `Unsupported file type. \n Allowed extensions: ${this.allowedExtensions}`;
				this.wrongInput = true;
				this.$refs.fileUpload.value = "";
				setTimeout(() => {
					this.wrongInput = false;
				}, this.errorTimeout);
				return;
			}
			if (files[0]) this.fileHandler(files[0]);
		},
		async fileHandler(f) {
			if (f.equals(this.lastFile)) return;
			this.reader.readAsText(f);
			this.listLoading = true;
			this.listUpload = false;
			this.reader.onloadend = (async () => {
				await this.sendToParser(this.reader.result, f);
			}).bind(this);
		},
		async sendToParser(res, f, fromPaste = false) {
			setTimeout(() => {
				this.parseProgress = 0;
				const tempResults = {
					data: res,
					meta: {
						name: f.name,
						size: f.size,
						lastModified: f.lastModified,
						fromPaste,
					},
				};
				this.csvParseWorker.postMessage({
					event: "parseCSV",
					data: { results: tempResults, headers: ["player_id"] },
					parser: this.parser,
				});
			}, 100);
		},
		async load() {
			this.listLoading = true;
			this.offerCode = this.$route.params.offer_id;
			this.listLoading = false;
		},
		onCSVParserDone(data) {
			if (this.validate(data) != true) return;
			if (this.parser === "offerPlayers") {
				localStorage.setItem("logan2:importDialog:hide", this.dontShow);
			}
			this.parserResult = data;
			this.parseProgress = 100;
			if (data) {
				this.tempData = data.data;
				this.tempMode = data.mode === "Blacklist";
				this.results.meta = data.meta;
				this.noChecksumData = null;
			}
			setTimeout(() => {
				if (!this._importDialog) return;
				this.listLoading = false;
				this.parseProgress = 0;
				if (data) this.listUpload = true;
				this.lastFile = data.meta;
				this.$refs.fileUpload.value = "";
			}, 750);
		},
		validate(data) {
			const res = this.validator(data);

			if (res != true) {
				this.errorMessage =
					typeof res === "string"
						? res
						: "Make sure you grab the correct files!";
				this.wrongInput = true;
				this.parseProgress = 0;
				this.$refs.fileUpload.value = "";
				setTimeout(() => {
					this.wrongInput = false;
					this.errorMessage = "Make sure you grab the correct files!";
				}, this.errorTimeout);
				this.listLoading = false;
				if (this.lastFile) {
					this.listUpload = true;
				}
			}

			return res;
		},
		onCancelImport() {
			this.parseProgress = 0;
			setTimeout(() => {
				this.wrongInput = false;
				this.listLoading = false;
				if (this.lastFile) {
					this.listUpload = true;
				}
			}, 750);

			this.noChecksumData = null;
			this.$refs.fileUpload.value = "";
		},
		findDelimiter,
	},
	created() {
		this.csvParseWorker.addEventListener("message", (e) => {
			if (e.data.status === "no-checksum") {
				this.noChecksumData = e.data.data;
				this.$refs.uncheckedCSVConfirmDialog.showConfirm();
			}
			if (e.data.status == "progress") {
				this.parseProgress = e.data.value;
			}
			if (e.data.status === "done") {
				this.onCSVParserDone(e.data.res);
			}
			if (e.data.status === "error") {
				this.wrongInput = true;
				this.parseProgress = 0;
				this.$refs.fileUpload.value = "";
				this.errorMessage = e?.data?.data?.[0];
				setTimeout(() => {
					this.wrongInput = false;
				}, 1000);
				this.listLoading = false;
				if (this.lastFile) {
					this.listUpload = true;
				}
			}
		});
		this.load();
	},
	beforeUnmount() {
		removeEventListener("paste", this.grabPaste);
		this.csvParseWorker.terminate();
	},
	beforeDestroy() {
		removeEventListener("paste", this.grabPaste);
		this.csvParseWorker.terminate();
	},
};
</script>
<style>
.WIP {
	color: black;
	background: repeating-linear-gradient(
		45deg,
		var(--v-warning-base),
		var(--v-warning-base) 10px,
		#000000 10px,
		#000000 20px
	);
	background-size: 400% 400%;
	padding: 5px 0 !important;
	overflow: hidden;
	contain: content;
}

.VERYWIP {
	animation: gradient 17s linear infinite forwards;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
		filter: brightness(1);
	}
	10% {
		filter: brightness(1.5);
	}
	20% {
		filter: brightness(1);
	}
	30% {
		filter: brightness(1.5);
	}
	40% {
		filter: brightness(1);
	}
	50% {
		filter: brightness(1.5);
	}
	60% {
		filter: brightness(1);
	}
	70% {
		filter: brightness(1.5);
	}
	80% {
		filter: brightness(1);
	}
	90% {
		filter: brightness(1.5);
	}
	100% {
		background-position: 100% 50%;
		filter: brightness(1);
	}
}

.WIP > span {
	height: 50px;
	width: 100%;
	background-color: var(--v-warning-base);
	/* contain: content; */
	z-index: 30;
}
/* .WIP:before {
	content: "Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP Caution, WIP";
	position: absolute;
	line-height: 0.8;
	z-index: 1;
	width: 120%;
	transform: translate(-50%, 0);
	font-size: smaller;
} */

.v-tooltip__content {
	opacity: 1 !important;
}
.dropHere {
	min-height: 40vh;
	border-radius: 20px;
}
.listTable {
	min-width: 90%;
}

.download {
	animation: download 1.3s ease-in-out;
}

.loading {
	animation: loading 2s ease-in-out;
	animation-fill-mode: forwards;
}

.shake {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
}

@keyframes loading {
	from {
		transform: translateY(0px);
		opacity: 1;
	}
	to {
		transform: translateY(-40px);
		opacity: 0;
	}
}

@keyframes download {
	from {
		transform: translateY(0px);
		opacity: 1;
	}
	to {
		transform: translateY(40px);
		opacity: 0;
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 0, 0);
	}
	20%,
	80% {
		transform: translate3d(2px, 0, 0);
	}
	30%,
	50%,
	70% {
		transform: translate3d(-4px, 0, 0);
	}
	40%,
	60% {
		transform: translate3d(4px, 0, 0);
	}
}
</style>
