var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": _vm.maxWidth },
          on: {
            "click:outside": function ($event) {
              return _vm.listOnCancel(true)
            },
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              return _vm.listOnCancel(true)
            },
          },
          model: {
            value: _vm._importDialog,
            callback: function ($$v) {
              _vm._importDialog = $$v
            },
            expression: "_importDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _vm._t(
                "title",
                function () {
                  return [
                    _c(
                      "v-card-title",
                      {
                        staticClass: "primary lighten-1 font-weight-bold",
                        class: { WIP: _vm.WIP, VERYWIP: _vm.VERYWIP },
                      },
                      [
                        _c("span", { staticClass: "text-h5" }, [
                          _vm._v(
                            _vm._s(_vm.WIP ? "WIP: " : "") + _vm._s(_vm.title)
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
                { title: _vm.title, WIP: _vm.WIP, VERYWIP: _vm.VERYWIP }
              ),
              _c("input", {
                ref: "fileUpload",
                attrs: {
                  accept: _vm.format.join(", "),
                  type: "file",
                  hidden: "",
                },
                on: { change: _vm.fileSelect },
              }),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { flat: "" },
                      model: {
                        value: _vm.currentStep,
                        callback: function ($$v) {
                          _vm.currentStep = $$v
                        },
                        expression: "currentStep",
                      },
                    },
                    [
                      _vm.steps > 1
                        ? _c(
                            "v-stepper-header",
                            [
                              _c(
                                "v-stepper-step",
                                {
                                  attrs: {
                                    editable: _vm.maxStep > 1,
                                    complete: _vm.currentStep > 1,
                                    step: 1,
                                  },
                                },
                                [
                                  _vm._t("step-1", function () {
                                    return [_vm._v("Select file")]
                                  }),
                                ],
                                2
                              ),
                              _vm._l(_vm.steps - 1, function (step) {
                                return [
                                  _c("v-divider", {
                                    key: `divider-${step}`,
                                    class: `${
                                      _vm.currentStep > step ? `primary` : ``
                                    }`,
                                  }),
                                  _c(
                                    "v-stepper-step",
                                    {
                                      key: `step-${step}`,
                                      attrs: {
                                        complete: _vm.currentStep > step + 1,
                                        editable: _vm.maxStep > step,
                                        step: step + 1,
                                      },
                                    },
                                    [
                                      _vm._t(
                                        `step-${step + 1}-header`,
                                        function () {
                                          return [
                                            _vm._v(
                                              " Step " + _vm._s(step + 1) + " "
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "v-stepper-items",
                        [
                          _c(
                            "v-stepper-content",
                            { staticClass: "pa-0 ma-0", attrs: { step: "1" } },
                            [
                              _c(
                                "v-slide-y-transition",
                                { attrs: { "leave-absolute": "" } },
                                [
                                  !_vm.listUpload && _vm._importDialog
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "dropHere d-flex flex-column justify-center align-center",
                                          class: _vm.wrongInput ? "shake" : "",
                                          attrs: {
                                            color: _vm.dragging
                                              ? "primary"
                                              : "menu_background",
                                            dense: "",
                                          },
                                          on: {
                                            dragover: _vm.dragOver,
                                            dragenter: _vm.dragOver,
                                            dragleave: _vm.dragOver,
                                            drop: _vm.fileDrop,
                                            click: _vm.clickInput,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "my-4",
                                              class: [
                                                _vm.dragging
                                                  ? "kajot-text--text"
                                                  : "primary--text",
                                                _vm.wrongInput
                                                  ? "error--text"
                                                  : "",
                                                _vm.listLoading
                                                  ? "loading"
                                                  : "",
                                              ],
                                              attrs: { size: "60" },
                                            },
                                            [
                                              _vm._v(
                                                " mdi-arrow-up-bold-circle "
                                              ),
                                            ]
                                          ),
                                          !_vm.wrongInput &&
                                          !_vm.listUpload &&
                                          _vm.importDialog
                                            ? _c(
                                                "p",
                                                [
                                                  _vm._t(
                                                    "drop-info",
                                                    function () {
                                                      return [
                                                        _c(
                                                          "b",
                                                          {
                                                            staticClass:
                                                              "text-uppercase",
                                                            class: _vm.dragging
                                                              ? "kajot-text--text"
                                                              : "primary--text",
                                                          },
                                                          [_vm._v(" DROP ")]
                                                        ),
                                                        !_vm.dragging
                                                          ? [
                                                              _vm._v(
                                                                " your " +
                                                                  _vm._s(
                                                                    _vm.dropFileType
                                                                  ) +
                                                                  " file or "
                                                              ),
                                                              _c(
                                                                "b",
                                                                {
                                                                  staticClass:
                                                                    "text-uppercase",
                                                                  class:
                                                                    _vm.dragging
                                                                      ? "kajot-text--text"
                                                                      : "primary--text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " CLICK "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" here "),
                                                            ]
                                                          : _vm._e(),
                                                        _vm._v(" to "),
                                                        _c(
                                                          "b",
                                                          {
                                                            staticClass:
                                                              "text-uppercase",
                                                            class: _vm.dragging
                                                              ? "kajot-text--text"
                                                              : "primary--text",
                                                          },
                                                          [_vm._v(" import ")]
                                                        ),
                                                        _vm._v(" . "),
                                                      ]
                                                    },
                                                    { dragging: _vm.dragging }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _c("v-progress-circular", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.listLoading,
                                                expression: "listLoading",
                                              },
                                            ],
                                            attrs: {
                                              value: _vm.parseProgress,
                                              color: "primary",
                                            },
                                          }),
                                          _vm.wrongInput
                                            ? _c(
                                                "b",
                                                { staticClass: "error--text" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.errorMessage) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-slide-y-transition",
                                {
                                  attrs: {
                                    "leave-absolute": "",
                                    "hide-on-leave": "",
                                  },
                                },
                                [
                                  _vm.listUpload
                                    ? _c(
                                        "v-card",
                                        {
                                          key: "card-uploaded",
                                          staticClass:
                                            "dropHere d-flex flex-column justify-center align-center",
                                          class: { shake: _vm.wrongInput },
                                          attrs: {
                                            color: _vm.dragging
                                              ? "primary"
                                              : "menu_background",
                                          },
                                          on: {
                                            dragover: _vm.dragOver,
                                            dragenter: _vm.dragOver,
                                            dragleave: _vm.dragOver,
                                            drop: _vm.fileDrop,
                                            click: _vm.clickInput,
                                          },
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.listLoading,
                                                expression: "listLoading",
                                              },
                                            ],
                                            attrs: {
                                              value: _vm.parseProgress,
                                              color: "primary",
                                            },
                                          }),
                                          _c(
                                            "v-slide-y-reverse-transition",
                                            {
                                              attrs: {
                                                "leave-absolute": "",
                                                "hide-on-leave": "",
                                              },
                                            },
                                            [
                                              !_vm.listLoading && _vm.listUpload
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "dropHere d-flex flex-column justify-center align-center",
                                                      class: _vm.wrongInput
                                                        ? "error--text"
                                                        : "",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "my-4",
                                                          class: [
                                                            _vm.dragging
                                                              ? "kajot-text--text"
                                                              : "primary--text",
                                                            _vm.wrongInput
                                                              ? "error--text"
                                                              : "",
                                                          ],
                                                          attrs: { size: "60" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-file-delimited "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.results.meta
                                                              ?.name
                                                          )
                                                        ),
                                                      ]),
                                                      _vm.tempMode !== undefined
                                                        ? _c(
                                                            "b",
                                                            {
                                                              staticClass:
                                                                "primary--text",
                                                              class:
                                                                _vm.wrongInput
                                                                  ? "error--text"
                                                                  : "",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.tempMode
                                                                      ? "Blacklist"
                                                                      : "Whitelist"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._t(
                                                        "file-detail",
                                                        function () {
                                                          return [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      `${_vm.tempData?.length} records`
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        { data: _vm.tempData }
                                                      ),
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              Math.floor(
                                                                _vm.results.meta
                                                                  ?.size / 10.24
                                                              ) / 100
                                                            ) +
                                                            " kB "
                                                        ),
                                                      ]),
                                                      _c("p", [
                                                        _vm._v(
                                                          " Modified: " +
                                                            _vm._s(
                                                              new Date(
                                                                _vm.results.meta?.lastModified
                                                              ).toLocaleString()
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.steps - 1, function (step) {
                            return _c(
                              "v-stepper-content",
                              {
                                key: `content-${step + 1}`,
                                staticClass: "pa-0 ma-0",
                                attrs: { step: step + 1 },
                              },
                              [
                                _vm._t(
                                  `step-${step + 1}-content`,
                                  function () {
                                    return [
                                      _vm._v(
                                        " Step " +
                                          _vm._s(step + 1) +
                                          " content "
                                      ),
                                    ]
                                  },
                                  {
                                    parserResult: _vm.parserResult,
                                    step: step + 1,
                                  }
                                ),
                              ],
                              2
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "justify-space-between w-100" },
                [
                  _vm.currentStep == 1
                    ? _vm._t("actions1", function () {
                        return [
                          _c(
                            "div",
                            [
                              _vm._t(
                                "actions1-cancel",
                                function () {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.listOnCancel(true)
                                          },
                                        },
                                      },
                                      [
                                        _vm._t(
                                          "actions1-cancel-text",
                                          function () {
                                            return [_vm._v("Cancel")]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                                { cancel: _vm.listOnCancel }
                              ),
                            ],
                            2
                          ),
                          !_vm.noHint ? _c("v-spacer") : _vm._e(),
                          _vm._t("actions1-extra", null, {
                            listUpload: _vm.listUpload,
                          }),
                          !_vm.noHint
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "", color: "transparent" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on: tooltip }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    text: "",
                                                    color: "warning",
                                                  },
                                                },
                                                tooltip
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { size: "25" } },
                                                  [_vm._v("mdi-information")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3802218746
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { "max-width": "300" } },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "warning lighten-1 font-weight-bold",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-h5" },
                                            [
                                              _vm._t("hint-title", function () {
                                                return [
                                                  _vm._v(
                                                    "Imported data format"
                                                  ),
                                                ]
                                              }),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        [_vm._t("hint-body")],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.noHint ? _c("v-spacer") : _vm._e(),
                          _c(
                            "div",
                            [
                              _vm.currentStep < _vm.steps
                                ? _vm._t(
                                    "actions1-next",
                                    function () {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.stepperNext()
                                              },
                                            },
                                          },
                                          [
                                            _vm._t(
                                              "actions1-next-text",
                                              function () {
                                                return [_vm._v("Next")]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                    {
                                      listUpload: _vm.listUpload,
                                      step: _vm.currentStep,
                                      next: _vm.stepperNext,
                                    }
                                  )
                                : _vm._e(),
                              _c(
                                "v-slide-y-transition",
                                [
                                  _vm._t(
                                    "actions1-submit",
                                    function () {
                                      return [
                                        !_vm.useConfirm
                                          ? _c(
                                              "v-btn",
                                              {
                                                key: "listButtonKey",
                                                attrs: {
                                                  disabled: !_vm.listUpload,
                                                  color: "primary",
                                                  text: "",
                                                },
                                                on: { click: _vm.submitList },
                                              },
                                              [
                                                _vm._t(
                                                  "actions1-submit-text",
                                                  function () {
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.submitButtonText
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _c("confirm-dialog", {
                                              attrs: {
                                                persistent: "",
                                                confirmBtnText: "Overwrite",
                                                cancelBtnText: "Back",
                                                disabled: !_vm.listUpload,
                                                shouldShow:
                                                  _vm.listMode !== "No List",
                                              },
                                              on: { confirm: _vm.submitList },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "body",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          " This action will overwrite all your currently set list. "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      showConfirm,
                                                    }) {
                                                      return [
                                                        _vm.listUpload
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                key: "listButtonKey",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  text: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    showConfirm,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Submit "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1935632576
                                              ),
                                            }),
                                      ]
                                    },
                                    { submit: _vm.submitList }
                                  ),
                                ],
                                2
                              ),
                            ],
                            2
                          ),
                        ]
                      })
                    : _vm._e(),
                  _vm.currentStep != 1
                    ? _vm._t(`actions${_vm.currentStep}`, null, {
                        cancel: _vm.listOnCancel,
                        prev: _vm.stepperBack,
                        next: _vm.stepperNext,
                        submit: _vm.submitList,
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("ConfirmDialog", {
        ref: "uncheckedCSVConfirmDialog",
        attrs: {
          titleClass: "error",
          cancelBtnText: "Back",
          shouldShow: !_vm.dontShow,
          cancelBtnColor: "kajot-text",
          confirmBtnText: "Import Anyway",
          titleText: "Importing CSV without checksum",
        },
        on: {
          confirm: function ($event) {
            return _vm.onCSVParserDone({
              data: _vm.noChecksumData.data,
              mode: _vm.noChecksumData.mode,
              meta: _vm.noChecksumData.meta,
            })
          },
          cancel: _vm.onCancelImport,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("h3", { staticClass: "error--text" }, [
                  _vm._v(
                    " Note: The validity of subject data CANNOT be guaranteed. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " Your CSV file is missing our checksum validation field. For security reasons, we recommend using CSV files generated by the LOGAN app. "
                  ),
                ]),
                _c(
                  "v-row",
                  {
                    staticClass:
                      "d-flex flex-row align-center justify-space-around",
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "1" } },
                      [
                        _c("v-checkbox", {
                          model: {
                            value: _vm.dontShow,
                            callback: function ($$v) {
                              _vm.dontShow = $$v
                            },
                            expression: "dontShow",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("v-col", [
                      _c("span", [_vm._v("Don't ask again in this session")]),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }